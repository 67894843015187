<template>
  <v-container fluid>
    <v-row>
      <!--<v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <DocumentCustomerSearch />
      </v-col>-->
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.status"
          :items="documentStatus"
          item-text="name"
          return-object
          label="Status"
          prepend-inner-icon="mdi-progress-check"
          outlined
          :rules="[v => !!v || 'Verkäufer muss ausgewählt werden']"
          required
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.salespersonId"
          :items="salespersons"
          item-value="lfdnr"
          item-text="name"
          label="Verkäufer"
          prepend-inner-icon="mdi-account-tie"
          outlined
          :rules="[v => !!v || 'Verkäufer muss ausgewählt werden']"
          required
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.branchOffice"
          :items="branchOffices"
          item-text="name"
          return-object
          label="Filiale"
          prepend-inner-icon="mdi-office-building-outline"
          outlined
          :rules="[v => !!v || 'Filiale muss ausgewählt werden']"
          required
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.warehouse"
          :items="warehouses"
          return-object
          item-text="name"
          label="Lager"
          prepend-inner-icon="mdi-warehouse"
          outlined
          :rules="[v => !!v || 'Lager muss ausgewählt werden']"
          required
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <BusinessDatePicker
          :name="
            document.documentTypeId == 35 ? 'Wunschtermin' : 'Bindungsfrist'
          "
          :date="document.date"
          @changed-date="changeDate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BusinessDatePicker from './BusinessDatePicker.vue'
//import DocumentCustomerSearch from './businessSearch/DocumentCustomerSearch.vue'

import { mapState } from 'vuex'

export default {
  components: {
    BusinessDatePicker
    //DocumentCustomerSearch
  },
  data: () => ({}),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document,
      documentStatus: state => state.businessDocumentGeneral.status,
      salespersons: state => state.businessDocumentGeneral.salespersons,
      branchOffices: state => state.businessDocumentGeneral.branchOffices,
      warehouses: state => state.businessDocumentGeneral.warehouses
    })
  },

  methods: {
    changeDate(newDate) {
      this.document.date = newDate
    },
    loadData() {
      this.$store.dispatch('getAllDocumentStatus')
      this.$store.dispatch('getAllSalespersons')
      this.$store.dispatch('getAllBranchOffices')
      this.$store.dispatch('getAllWarehouses')
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style></style>
