<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="componentDate"
        :label="name"
        outlined
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="[v => !!v || 'Datum muss ausgewählt werden']"
        requried
      ></v-text-field>
    </template>
    <v-date-picker color="primary" v-model="componentDate" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    date: {
      type: Date,
      required: true
    }
  },
  data: () => ({
    componentDate: null,
    data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    menu: false
  }),
  watch: {
    componentDate(newVal) {
      this.$emit('changed-date', newVal)
    }
  },
  mounted() {
    this.componentDate = this.date
  }
}
</script>

<style></style>
