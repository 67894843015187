<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="positionHeaders"
          :items="document.positions"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          class="rounded-lg"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                <DocumentPositionSearch />
              </v-col>
            </v-row>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            class="elevation-0"
          >
            <td :colspan="headers.length">
              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    v-model="item.characteristic"
                    :items="item.possibleCharacteristics"
                    item-text="id"
                    return-object
                    @change="() => changedCharacteristic(item)"
                    label="Sachmerkmal"
                    prepend-inner-icon="mdi-shape"
                    outlined
                    :rules="[v => !!v || 'Sachmerkmal muss ausgewählt werden']"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model="item.quantity"
                    label="Menge"
                    prepend-inner-icon="mdi-counter"
                    outlined
                    :suffix="item.quantityUnit.code"
                    :disabled="item.characteristic == null"
                    :rules="quantityRules"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" xs="12" sm="6" md="6" lg="2" xl="2">
                  <v-text-field
                    v-model="item.pricing.netPrice"
                    label="Nettopreis"
                    prepend-inner-icon="mdi-cash"
                    outlined
                    @change="calculateGrossPrice(item)"
                    :suffix="
                      document.billing.currency !== undefined
                        ? document.billing.currency.symbol
                        : ''
                    "
                    :rules="netPriceRules"
                    required
                    :disabled="item.characteristic == null"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="2" xl="2">
                  <v-text-field
                    v-model="item.pricing.grossPrice"
                    label="Bruttopreis"
                    prepend-inner-icon="mdi-cash"
                    outlined
                    @change="calculateNetPrice(item)"
                    :suffix="
                      document.billing.currency !== undefined
                        ? document.billing.currency.symbol
                        : ''
                    "
                    :rules="grossPriceRules"
                    required
                    :disabled="item.characteristic == null"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="2" xl="2">
                  <v-text-field
                    v-model="item.pricing.discount"
                    label="Rabatt"
                    prepend-inner-icon="mdi-percent"
                    outlined
                    suffix="%"
                    :rules="discountRules"
                    required
                    :disabled="item.characteristic == null"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="2" xl="2">
                  <v-autocomplete
                    v-model="item.pricing.valueAddedTax"
                    :items="item.pricing.possibleValueAddedTaxes"
                    item-text="name"
                    return-object
                    label="Umsatzsteuer"
                    prepend-inner-icon="mdi-currency-eur"
                    outlined
                    :rules="[v => !!v || 'Umsatzsteuer muss ausgewählt werden']"
                    required
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="removePosition(item)">mdi-cart-remove</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import DocumentPositionSearch from './businessSearch/DocumentPositionSearch.vue'

export default {
  components: {
    DocumentPositionSearch
  },
  data: () => ({
    expanded: [],
    singleExpand: true,
    positionHeaders: [
      {
        text: 'Artikelnummer',
        align: 'start',
        value: 'articleInformation.artNr'
      },
      {
        text: 'Artikelbezeichnung',
        align: 'start',
        value: 'articleInformation.articleDescription'
      },
      { text: 'Sachmerkmal', align: 'start', value: 'characteristic.id' },
      {
        text: 'Aktionen',
        align: 'center',
        value: 'actions'
      }
    ],
    quantityRules: [
      v => !!v || 'Menge muss eingegeben werden',
      v => /^[0-9]+$/.test(v) || 'Menge muss eine Zahl sein'
    ],
    netPriceRules: [
      v => !!v || 'Nettopreis muss eingegeben werden',
      v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Nettopreis muss eine Zahl sein'
    ],
    grossPriceRules: [
      v => !!v || 'Bruttopreis muss eingegeben werden',
      v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Bruttopreis muss eine Zahl sein'
    ],
    discountRules: [
      v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Rabatt muss eine Zahl sein'
    ]
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    }),
    calculateSumPrices(position) {
      var sumPrices = {
        sumNetPrice: position.pricing.netPrice * position.quantity,
        sumGrossPrice: position.pricing.grossPrice * position.quantity
      }

      return sumPrices
    }
  },
  methods: {
    calculateNetPrice(position) {
      position.pricing.netPrice =
        position.pricing.grossPrice / (position.pricing.valueAddedTax.tax + 1)
    },
    calculateGrossPrice(position) {
      position.pricing.grossPrice =
        position.pricing.netPrice * (position.pricing.valueAddedTax.tax + 1)
    },
    async changedCharacteristic(position) {
      var articleId = position.articleInformation.artNr
      var characteristicId = position.characteristic.id
      var possibleValueAddedTaxes = position.pricing.possibleValueAddedTaxes
      await axios
        .get(
          `v1/business/document/positions/article/${articleId}/characteristic/${characteristicId}/pricing`
        )
        .then(response => {
          position.pricing = response.data
        })

      position.pricing.possibleValueAddedTaxes = possibleValueAddedTaxes
    },

    removePosition(item) {
      this.document.positions = this.document.positions.filter(position => {
        return item.id !== position.id
      })
    }
  }
}
</script>

<style></style>
