<template>
  <v-card flat>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-expansion-panels multiple accordion :value="expanded" flat>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="primary--text text-h5 font-weight-black pl-9 pt-6"
            >
              Allgemein
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DocumentGeneral />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="primary--text text-h5 font-weight-black pl-9 pt-6"
            >
              Liefer- und Zahlungsdetails
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DocumentDeliveryBillingDetails />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel disabled>
            <v-expansion-panel-header
              class="primary--text text-h5 font-weight-black pl-9 pt-6"
            >
              Verordnungen
              <span class="ml-3">
                <v-chip>
                  <v-icon left>
                    mdi-bullhorn-outline
                  </v-icon>
                  Bald Verfügbar
                </v-chip>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DocumentPrescription />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="primary--text text-h5 font-weight-black pl-9 pt-6"
            >
              Positionen
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DocumentPositions />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-container>
          <v-row class="d-flex">
            <v-col cols="12" align="center">
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="createDocument()"
              >
                Erstellen
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import DocumentGeneral from '../../components/business/DocumentGeneral.vue'
import DocumentDeliveryBillingDetails from '../../components/business/BusinessDeliveryBilling.vue'
import DocumentPrescription from '../../components/business/DocumentPrescription.vue'
import DocumentPositions from '../../components/business/DocumentPositions.vue'

import { DocumentClass } from '../../models/business/BusinessClasses'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {
    DocumentGeneral,
    DocumentDeliveryBillingDetails,
    DocumentPrescription,
    DocumentPositions
  },
  data: () => ({
    expanded: [0, 1, 3],
    valid: false
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    })
  },
  methods: {
    async createDocument() {
      await this.$refs.form.validate()
      if (this.valid) {
        this.requestDocumentCreation()
      }
    },
    requestDocumentCreation() {
      axios
        .post(`v1/business/document/create`, this.document)
        .then(response => {
          this.$router.push({
            path:
              '/crm/addresses/' +
              response.data.customerId +
              '/documents/' +
              response.data.wfCid
          })
        })
    }
  },
  mounted() {
    this.document.customerId = this.$route.query.address
    this.document.typeId = this.$route.query.documentTypeId
  },
  destroyed() {
    this.document = DocumentClass.getEmptyDocument()
  }
}
</script>

<style></style>
