v-autocomplete<template>
  <v-autocomplete
    v-model="selectedArticle"
    :items="articles"
    item-text="articleDescription"
    return-object
    no-filter
    :search-input.sync="articleSearchInput"
    :loading="articleSearchLoading"
    @change="() => addArticleToPositions()"
    loading-text="Artikel werden geladen"
    no-data-text="Keine Artikel gefunden"
    label="Artikelsuche"
    placeholder="Name des Artikels"
    prepend-inner-icon="mdi-cart-variant"
    class="pt-2 px-2"
    outlined
    clearable
  ></v-autocomplete>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { DocumentPositionClass } from '@/models/business/BusinessClasses'

export default {
  data: () => ({
    articleFullTextSearchUrl: '/v1/articles/search',
    articleSearchInput: '',
    articleSearchLoading: false,
    articles: [],
    selectedArticle: null,
    positionLfdnr: 0
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    })
  },
  watch: {
    articleSearchInput(newValue) {
      if (newValue === '' || newValue === null) {
        this.articles = []
      } else {
        this.searchTrigger()
      }
    }
  },
  methods: {
    async addArticleToPositions() {
      if (this.selectedArticle == null) return

      var convertedPosition = DocumentPositionClass.createFromArticleInformation(
        this.selectedArticle
      )
      convertedPosition.possibleCharacteristics = await axios
        .get(
          `v1/business/document/positions/article/${convertedPosition.articleInformation.artNr}/characteristics`
        )
        .then(response => {
          return response.data
        })
      convertedPosition.pricing.possibleValueAddedTaxes = await axios
        .get(
          `v1/business/document/positions/article/${convertedPosition.articleInformation.artNr}/value-added-tax`
        )
        .then(respones => {
          return respones.data
        })

      convertedPosition.id = this.positionLfdnr

      this.document.positions.push(convertedPosition)

      setTimeout(() => {
        this.articleSearchInput = ''
        this.positionLfdnr++
      }, 500)
    },
    initiateSearchUI() {
      this.articleSearchLoading = true
      this.articles = []
    },
    searchFullText(query) {
      if (query == undefined || query == '') return

      this.initiateSearchUI()

      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel('Operation canceled due to new request.')
      }

      //Save the cancel token for the current request
      this.cancelToken = axios.CancelToken.source()

      axios
        .get(this.articleFullTextSearchUrl, {
          params: { q: query },
          cancelToken: this.cancelToken.token
        })
        .then(response => {
          this.articles = response.data
          this.searchSuccessfull = true
          this.articleSearchLoading = false
        })
        .catch(err => {
          console.log('error searching address: ' + err)
        })
    },
    searchTrigger() {
      if (
        this.articleSearchInput != null &&
        this.articleSearchInput != '' &&
        String(this.articleSearchInput).length > 2
      ) {
        this.searchStartedOnce = true
        this.searchFullText(this.articleSearchInput)
      }
    }
  },
  destroyed() {}
}
</script>

<style></style>
