<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <BusinessDatePicker
          name="Lieferdatum"
          :date="document.delivery.date"
          @changed-date="changedDeliveryDate"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.delivery.type"
          :items="deliveryTypes"
          item-text="name"
          return-object
          label="Versandart"
          prepend-inner-icon="mdi-truck-delivery-outline"
          outlined
          :rules="[v => !!v || 'Versandart muss ausgewählt werden']"
          required
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.delivery.condition"
          :items="deliveryConditions"
          item-text="name"
          return-object
          label="Lieferbedingungen"
          prepend-inner-icon="mdi-map-marker-outline"
          outlined
          :rules="[v => !!v || 'Lieferbedingung muss ausgewählt werden']"
          required
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <BusinessDatePicker
          name="Zahlungsdatum"
          :date="document.billing.date"
          @changed-date="changedBillingDate"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.billing.type"
          :items="billingTypes"
          item-text="name"
          return-object
          label="Zahlungsart"
          prepend-inner-icon="mdi-credit-card-outline"
          outlined
          :rules="[v => !!v || 'Zahlungsart muss ausgewählt werden']"
          required
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="document.billing.condition"
          :items="billingConditions"
          item-text="name"
          return-object
          label="Zahlungsbedingungen"
          prepend-inner-icon="mdi-map-marker-outline"
          outlined
          :rules="[v => !!v || 'Zahlungsbedingung muss ausgewählt werden']"
          required
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
        <v-autocomplete
          v-model="document.billing.currency"
          :items="billingCurrencies"
          item-text="description"
          return-object
          label="Währung"
          prepend-inner-icon="mdi-currency-eur"
          outlined
          :rules="[v => !!v || 'Währung muss ausgewählt werden']"
          required
        >
          <template v-slot:item-text="{ item }">
            {{ item.symbol + ' -c ' + item.description }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.symbol + ' - ' + item.description }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          v-model="document.billing.discount"
          label="Kopfrabatt"
          prepend-inner-icon="mdi-percent"
          outlined
          :rules="discountRules"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import BusinessDatePicker from './BusinessDatePicker.vue'

export default {
  components: {
    BusinessDatePicker
  },
  data: () => ({
    discountRules: [
      v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Rabatt muss eine Zahl sein'
    ]
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document,
      deliveryTypes: state =>
        state.businessDocumentDeliveryBilling.deliveryTypes,
      deliveryConditions: state =>
        state.businessDocumentDeliveryBilling.deliveryConditions,
      billingTypes: state => state.businessDocumentDeliveryBilling.billingTypes,
      billingConditions: state =>
        state.businessDocumentDeliveryBilling.billingConditions,
      billingCurrencies: state =>
        state.businessDocumentDeliveryBilling.billingCurrencies
    })
  },
  methods: {
    changedDeliveryDate(newDate) {
      this.document.delivery.date = newDate
    },
    changedBillingDate(newDate) {
      console.log(newDate)
      this.document.billing.date = newDate
    },
    loadData() {
      this.$store.dispatch('getAllDeliveryTypes')
      this.$store.dispatch('getAllDeliveryConditions')
      this.$store.dispatch('getAllBillingTypes')
      this.$store.dispatch('getAllBillingConditions')
      this.$store.dispatch('getAllBillingCurrencies')
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style></style>
